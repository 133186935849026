import { useApolloClient, useQuery, useReactiveVar } from "@apollo/client";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";

import paths from "../../config/paths";
import { CURRENT_ACCOUNT } from "../../graphql/localStates/account";
import { SOCKET_ID } from "../../graphql/localStates/root";
import { GET_PLATFORM_TARGETS } from "../../graphql/queries/platform";
import { PLATFORM_TARGET_CONNECT_STATUS } from "../../graphql/subscriptions/platform";
import useDeepCompareEffect from "../../hooks/use-deep-compare-effect";
import useSubscriptionWithContext from "../../hooks/useSubscriptionWithContext";
import { scrollable } from "../common/css-snippets";
import {
  FacebookButton,
  GoogleButton,
  GymLeadsButton,
  GymSalesButton,
  InstagramButton,
  LinkedinButton,
  MindbodyButton,
  TikTokButton, // TwitterButton,
} from "../common/platform-buttons-and-links";
import TargetCard from "./target";

const API_URL = import.meta.env.VITE_API_URL;
const CLIENT_URL = import.meta.env.VITE_SITE_URL;

const Content = styled.div`
  padding: 0 16px 16px;
  ${scrollable}
`;

const Primary = styled.div`
  padding-top: 1px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PLATFORM_BUTTONS = {
  Facebook: FacebookButton,
  Instagram: InstagramButton,
  MindBody: MindbodyButton,
  GymSales: GymSalesButton,
  GymLeads: GymLeadsButton,
  Google: GoogleButton,
  Linkedin: LinkedinButton,
  TikTok: TikTokButton,
  // Twitter: TwitterButton,
};

const Targets = () => {
  const [targets, setTargets] = useState([]);
  const [possibleInstagramParents, setPossibleInstagramParents] = useState(new Map());
  const currentAccountId = useReactiveVar(CURRENT_ACCOUNT);
  const socketId = useReactiveVar(SOCKET_ID);

  const apolloClient = useApolloClient();

  const { data: getPlatformTargets } = useQuery(GET_PLATFORM_TARGETS, {
    variables: {
      accountId: currentAccountId,
    },
  });

  useDeepCompareEffect(() => {
    if (!getPlatformTargets?.platformTargets) return;
    const targetsMap = new Map();
    const possibleIgParents = new Map();
    getPlatformTargets.platformTargets.forEach((platformTarget) => {
      const { platform } = platformTarget;
      if (platform === "facebook" && !platformTarget.parentId) possibleIgParents.set(platformTarget.id, platformTarget);
      if (!targetsMap.has(platform)) {
        targetsMap.set(platform, []);
      }
      targetsMap.get(platform).push(platformTarget);
    });
    setTargets([...targetsMap.entries()]);
    setPossibleInstagramParents(possibleIgParents);
  }, [getPlatformTargets?.platformTargets]);

  useSubscriptionWithContext(PLATFORM_TARGET_CONNECT_STATUS, {
    shouldResubscribe: true,
    fetchPolicy: "network-only",
    onData: async ({ data: { data } }) => {
      const { platformTargetConnectStatus } = data;
      if (platformTargetConnectStatus?.id) await apolloClient.refetchQueries({ include: [GET_PLATFORM_TARGETS] });
    },
  });

  const connect = async (platformName, payload) => {
    try {
      let platform = platformName.toLowerCase();
      platform = platform === "instagram" ? "facebook" : platform;

      if (!currentAccountId) return;
      const url = new URL(`${API_URL}/connect/${platform}`);
      url.search = new URLSearchParams({
        accountId: currentAccountId,
        socketId,
        payload: payload ? JSON.stringify(payload) : {},
      });

      const width = 700;
      const height = 800;
      const left = Math.round(window.screenX + (window.outerWidth - width) / 2);
      const top = Math.round(window.screenY + (window.outerHeight - height) / 2.5);
      const windowFeatures = `width=${width},height=${height},left=${left},top=${top},toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0,popup=0`;
      window.open(url.href, "_blank", windowFeatures);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Content>
      <Primary>
        {targets.map(([platform, platformTargets]) => (
          <TargetCard
            connect={() => {}}
            key={platform}
            platform={platform}
            platformTargets={platformTargets}
            possibleInstagramParents={possibleInstagramParents}
          />
        ))}

        {Object.entries(PLATFORM_BUTTONS).map(([platformName, PlatformButton]) => (
          <PlatformButton
            cta={`Add ${platformName} account`}
            key={platformName}
            onClick={(_, payload) => connect(platformName, payload)}
          />
        ))}
      </Primary>
    </Content>
  );
};

export default Targets;
